{
  "name": "talent-portal",
  "version": "0.0.1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --disable-host-check --public-host=0.0.0.0:4200 --host=0.0.0.0 ",
    "build": "ng build",
    "build:test": "ng build --configuration=test",
    "build:prod": "ng build --configuration=production",
    "watch": "ng build --watch --configuration development",
    "test": "ng test --code-coverage --watch=false --browsers ChromeHeadlessNoSandbox --reporters progress,junit",
    "copy-schema": "node ../script/copy-schema.js",
    "prepare": "relative-deps"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.12",
    "@angular/cdk": "^18.2.14",
    "@angular/common": "^18.2.12",
    "@angular/compiler": "^18.2.12",
    "@angular/core": "^18.2.12",
    "@angular/forms": "^18.2.12",
    "@angular/material": "^18.2.14",
    "@angular/platform-browser": "^18.2.12",
    "@angular/platform-browser-dynamic": "^18.2.12",
    "@angular/router": "^18.2.12",
    "@ngrx/signals": "^18.1.1",
    "@ngrx/store": "^18.1.1",
    "@propeld-ng/model": "file:.yalc/@propeld-ng/model",
    "echarts": "^5.4.3",
    "jasmine": "^5.1.0",
    "karma-junit-reporter": "^2.0.1",
    "ngrx-immer": "^2.1.1",
    "ngx-echarts": "^17.1.0",
    "rxjs": "~7.8.0",
    "sockjs-client": "^1.6.1",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.12",
    "@angular/cli": "^18.2.12",
    "@angular/compiler-cli": "^18.2.12",
    "@angular/localize": "^18.2.12",
    "@types/jasmine": "~4.3.0",
    "jasmine-core": "~5.1.1",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "karma-junit-reporter": "2.0.1",
    "relative-deps": "^0.0.2",
    "typescript": "~5.5.4"
  },
  "relativeDependencies": {
    "propeld-ng": "../../propeld-ng/dist/propeld-ng"
  }
}
