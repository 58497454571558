<div id="topElement" class="title section">
  <div class="inner">
    <h1>{{ model$().jobTitle }}</h1>
  </div>
</div>

<div class="test section">
  <div class="box">

    <div class="box-left">
      <div class="centered">

        @if (token === "") {
          <button mat-flat-button [routerLink]="['../']">
            <mat-icon>
              <span class="material-symbols-outlined">chevron_left</span>
            </mat-icon>
            Terug naar overzicht
          </button>
        } @else {
          <div style="height:68px"></div>
        }

        @if (model$().ppId != null && model$().ppId > 0) {
          <div class="profile-picture">
            <img class="photo" src="{{ profilePictureUrl$() }}">
          </div>
        }

        <div class="details">
          <h2>Functie</h2>
          <p [innerHTML]="((model$().jobTitle ?? '') | wrapcomma:30) | safe: 'resourceHtml'"></p>

          <h2>Regio's</h2>
          <p>{{ model$().region }}</p>
        </div>
      </div>

    </div>
    <div class="box-right">

        <div class="first-row button">
          <button mat-flat-button (click)="setFormOpen()">
            @if (additionalDataDisclosed()) {
              Ik ben geïnteresseerd!
            } @else {
              Vertel mij meer
            }
          </button>
        </div>

      <div class="first-row header-and-button">
        <h2>Persoonlijke gegevens</h2>

          <button mat-flat-button (click)="setFormOpen()">
            @if (additionalDataDisclosed()) {
              Ik ben geïnteresseerd!
            } @else {
              Vertel mij meer!
            }
          </button>
      </div>

      <div class="block">

        <!-- region jobTitle / Functietitel -->
        @if (model$().jobTitle != null && model$().jobTitle != '') {
          <div class="form-row">
            <label class="label-column" for="jobTitle">
              <p class="body-1">Functietitel</p>
            </label>

            <div class="input-column">
              <p id="jobTitle" class="display">{{ (model$().jobTitle ?? '') }}</p>
            </div>
          </div>
        }
        <!-- endregion -->

        <!-- region desiredJobTitle / Gewenste functie -->
        @if (model$().desiredJobTitle != null && model$().desiredJobTitle != '') {
          <div class="form-row">
            <label class="label-column" for="desiredJobTitle">
              <p class="body-1">Gewenste functie</p>
            </label>

            <div class="input-column">
              <p id="desiredJobTitle" class="display">{{ (model$().desiredJobTitle ?? '') }}</p>
            </div>
          </div>
        }
        <!-- endregion -->

        <!-- region functionArea / -->
        @if (model$().functionArea != null && model$().functionArea != '') {
          <div class="form-row">
            <label class="label-column" for="functionArea">
              <p class="body-1">Functiegebied</p>
            </label>

            <div class="input-column">
              <p id="functionArea" class="display">{{ model$().functionArea }}</p>
            </div>
          </div>
        }
        <!-- endregion -->

        <!-- region industry / Branche -->
        @if (model$().industry != null && model$().industry != '') {
          <div class="form-row">
            <label class="label-column" for="industry">
              <p class="body-1">Branche</p>
            </label>

            <div class="input-column">
              <p id="industry" class="display">{{ model$().industry }}</p>
            </div>
          </div>
        }
        <!-- endregion -->

        <!-- region workLevelId / Ervaring-->
        @if (model$().workLevelId != null && model$().workLevelId != 0) {
          <div class="form-row">
            <label class="label-column" for="workLevel">
              <p class="body-1">Ervaring</p>
            </label>

            <div id="workLevel" class="input-column">
              <p>{{ getCandidateWorkLevelName(model$().workLevelId) }}</p>
            </div>
          </div>
        }
        <!-- endregion -->

        <!-- region functionLevel / Functieniveau-->
        @if (model$().functionLevel != null) {
          <div class="form-row">
            <label class="label-column" for="functionLevel">
              <p class="body-1">Functieniveau</p>
            </label>

            <div id="functionLevel" class="input-column">
              <p>{{ getCandidateFunctionLevelName(model$().functionLevel) }}</p>
            </div>
          </div>
        }
        <!-- endregion -->

        <!-- region region / Regio -->
        @if (model$().region != null && model$().region != '') {
          <div class="form-row">
            <label class="label-column" for="region">
              <p class="body-1">Regio</p>
            </label>

            <div class="input-column">
              <p id="region" class="display">{{ model$().region }}</p>
            </div>
          </div>
        }
        <!-- endregion -->

        <!-- region availability / Beschikbaarheid -->
        @if (model$().availability != null && model$().availability != '') {
          <div class="form-row">
            <label class="label-column" for="availability">
              <p class="body-1">Beschikbaarheid</p>
            </label>

            <div id="availability" class="input-column">
              <p>{{ getCandidateAvailabilityName(model$().availability) }}</p>
            </div>
          </div>
        }
        <!-- endregion -->

        <!-- region discStyle / Commerciele style -->
        @if (model$().discStyle != null && model$().discStyle != '') {
          <div class="form-row">
            <label class="label-column" for="discStyle">
              <p class="body-1">DISC stijl</p>
            </label>

            <div class="input-column">
              <autocomplete-input
                id="discStyle"
                class="display"
                [formControl]="discStyle"
                [displayOnly]="true"
                [className]="'discStyle'"
                [source]="discStyleSource"
                [filterField]="'discStyle'"
                [placeholder]="'DISC stijl'"
                [displayField]="'name'"
              ></autocomplete-input>

              <!--            <p id="discStyle" class="display">{{ model$().discStyle }}</p>-->
            </div>
          </div>
        }

        <!-- endregion -->

        <!-- region educationLevel / Opleiding -->
        @if (model$().educationLevel != null && model$().educationLevel != undefined && model$()
          .educationLevel
          ?.toString() != '') {
          <div class="form-row">
            <label class="label-column" for="educationLevel">
              <p class="body-1">Opleiding</p>
            </label>

            <div class="input-column">
              <p id="educationLevel" class="display">{{ model$().educationLevel?.name }}</p>
            </div>
          </div>
        }
        <!-- endregion -->

        <!-- region salaryIndication / Salarisindicatie -->
        @if (model$().salaryIndication != null && model$().salaryIndication != '') {
          <div class="form-row">
            <label class="label-column" for="salaryIndication">
              <p class="body-1">Salaris indicatie</p>
            </label>

            <div class="input-column">
              <p id="salaryIndication" class="display">{{ model$().salaryIndication }}</p>
            </div>
          </div>
        }

        <!-- endregion -->

        <!-- region age / Leeftijd -->
        @if (model$().age != null) {
          <div class="form-row">
            <label class="label-column" for="age">
              <p class="body-1">Leeftijd</p>
            </label>

            <div class="input-column">
              <p id="age" class="display">
                {{ model$().age }}
              </p>
            </div>
          </div>
        }
        <!-- endregion -->

        <!-- region drivingLicence / Rijbewijs -->
        @if (model$().drivingLicence != null) {
          <div class="form-row">
            <label class="label-column" for="drivingLicence">
              <p class="body-1">Rijbewijs</p>
            </label>

            <div class="input-column">
              <p id="drivingLicence" class="display">
                @if (model$().drivingLicence) {
                  Ja
                } @else {
                  Nee
                }
              </p>
            </div>
          </div>
        }
        <!-- endregion -->

      </div>

      @if (!isMobile() && additionalDataDisclosed()) {
        <div class="form-row middle">
          <button mat-flat-button (click)="setFormOpen()">
            @if (additionalDataDisclosed()) {
              Ik ben geïnteresseerd!
            } @else {
              Vertel mij meer
            }
          </button>
        </div>
      }

      @if (accountService.get().hasPermission("candidate/always-view-additional-data")) {
        <div class="form-row" style="margin-top: 32px;">
          <h2>Onderstaande data wordt op aanvraag vrijgegeven</h2>
        </div>
      }

      <div id="additionalDataEl"></div>

      @if (additionalDataDisclosed()) {
        <div class="block">

          <!-- region vimeoLink -->
          @if (model$().vimeoLink != null && model$().vimeoLink != '') {
            <div class="form-row vimeoLink">
              <label class="label-column" for="vimeoLink">
                <p class="body-1">Presentatie</p>
              </label>

              <div class="input-column vimeoLink">
                <a id="vimeoLink" href="{{model$().vimeoLink}}" target="_blank">
                  Bekijk de presentatie
                  <mat-icon>
                    <span class="material-symbols-outlined">open_in_new</span>
                  </mat-icon>
                </a>
              </div>
            </div>
          }
          <!-- endregion / Werkervaring-->

          <!-- region info / Werkervaring -->
          @if (model$().info != null && model$().info != '') {
            <div class="form-row">
              <label class="label-column" for="info">
                <p class="body-1">Werkervaring</p>
              </label>

              <div class="input-column">
                <p id="info" class="display">{{ model$().info }}</p>
              </div>
            </div>
          }
          <!-- endregion / Werkervaring-->

          <!-- region personalInfo / Vaardigheden -->
          @if (model$().personalInfo != null && model$().personalInfo != '') {
            <div class="form-row">
              <label class="label-column" for="personalInfo">
                <p class="body-1">Vaardigheden</p>
              </label>

              <div class="input-column">
                <p id="personalInfo" class="display">{{ model$().personalInfo }}</p>
              </div>
            </div>
          }
          <!-- endregion -->

          <!-- region typeOfPerson / Competentiebeoordeling -->
          @if (model$().typeOfPerson != null && model$().typeOfPerson != '') {
            <div class="form-row">
              <label class="label-column" for="typeOfPerson">
                <p class="body-1">Competentiebeoordeling</p>
              </label>

              <div class="input-column">
                <p id="typeOfPerson" class="display">{{ model$().typeOfPerson }}</p>
              </div>
            </div>
          }
          <!-- endregion -->

          <!-- region competences / Persoonlijk profiel -->
          @if (model$().competences != null && model$().competences != '') {
            <div class="form-row">
              <label class="label-column" for="competences">
                <p class="body-1">Persoonlijk profiel</p>
              </label>

              <div class="input-column">
                <p id="competences" class="display">{{ model$().competences }}</p>
              </div>
            </div>
          }
          <!-- endregion -->

          <!-- region motivation / Overige -->
          @if (model$().motivation != null && model$().motivation != '') {
            <div class="form-row">
              <label class="label-column" for="motivation">
                <p class="body-1">Overige</p>
              </label>

              <div class="input-column">
                <p id="motivation" class="display">{{ model$().motivation }}</p>
              </div>
            </div>
          }

          <!-- endregion -->

        </div>
      }

      <div class="form-row end">
        <div class="left">
          @if (token === "") {
            <button mat-flat-button [routerLink]="['../']">
              <mat-icon>
                <span class="material-symbols-outlined">chevron_left</span>
              </mat-icon>
              Terug naar overzicht
            </button>
          }
        </div>

        <button mat-flat-button  (click)="setFormOpen()">
          @if (additionalDataDisclosed()) {
            Ik ben geïnteresseerd
          } @else {
            Vertel mij meer
          }
        </button>
      </div>

      <!-- region Tell me more button -->
      <div class="form-row">
        <label class="label-column"></label>
        <div class="input-column">
          <candidate-contact-form
            [additionalDataDisclosed]="additionalDataDisclosed()"
            [pageDisplayType]="'info'"
            [uuid]="model$().uuid"
            [token]="token"
            [source]="formSource"
          ></candidate-contact-form>
        </div>
      </div>
      <!-- end region -->

    </div>
  </div>
</div>
